// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("../src/templates/Service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-contact-js": () => import("../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-free-quote-js": () => import("../src/templates/FreeQuote.js" /* webpackChunkName: "component---src-templates-free-quote-js" */),
  "component---src-templates-work-with-us-js": () => import("../src/templates/WorkWithUs.js" /* webpackChunkName: "component---src-templates-work-with-us-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

